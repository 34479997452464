import {Box, Image, Link, Text, VStack} from '@chakra-ui/react'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import sponsorImage from '../assets/sponsor.png'
import image_logo from '../components/Common/logo_loading.png'
import {ROUTE_SIGN_IN} from '../router/routes'

const ApplicationsClosed = () => {
  const [clickCount, setClickCount] = useState(0)
  const navigate = useNavigate()

  const handleLogoClick = () => {
    const newCount = clickCount + 1
    setClickCount(newCount)

    // After 5 clicks, redirect to sign-in with admin=true
    if (newCount === 5) {
      navigate(`${ROUTE_SIGN_IN}?admin=true`)
    }

    // Reset counter after 2 seconds of no clicks
    setTimeout(() => {
      setClickCount(0)
    }, 2000)
  }

  return (
    <Box position='relative' h='100vh'>
      <VStack h='100%' justifyContent='space-between' spacing={8} py={8}>
        <Box />
        <VStack justifyContent='center' spacing={8} py={8}>
          <Image
            src={image_logo}
            alt='Prix de Lausanne'
            maxW='200px'
            onClick={handleLogoClick}
            style={{userSelect: 'none'}}
          />

          <Box maxW='600px' textAlign='center'>
            <Text fontSize='lg' color='gray.600' mb={4}>
              Thank you for being part of the 2025 Prix de Lausanne! We look forward to seeing you
              next year for another extraordinary celebration of dance and young talent. See you in
              2026!
            </Text>
            <Text fontSize='md' color='brand.primary'>
              Visit us at{' '}
              <Link href='https://www.prixdelausanne.org/' isExternal color='brand.accent'>
                www.prixdelausanne.org
              </Link>
            </Text>
          </Box>
        </VStack>

        <Box mt={8} p={4} bg='white' maxW='300px'>
          <VStack spacing={0}>
            <Text fontSize='sm' color='gray.600' fontStyle='italic' textAlign='center'>
              With the generous support of
            </Text>
            <Image
              src={sponsorImage}
              alt='Sponsor'
              objectFit='contain'
              width='100%'
              height='auto'
            />
          </VStack>
        </Box>
      </VStack>

      <Text position='absolute' bottom={2} right={4} fontSize='xs' color='gray.400'>
        Proudly developed by{' '}
        <Link
          href='https://www.appik-studio.ch'
          isExternal
          rel='noopener noreferrer'
          title='Appik Studio - Web & Mobile Development'
          aria-label='Visit Appik Studio website'
          color='gray.400'
          _hover={{color: 'gray.600'}}>
          Appik Studio
        </Link>
      </Text>
    </Box>
  )
}

export default ApplicationsClosed
