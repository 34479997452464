import {AnimatePresence} from 'framer-motion'
import {isApplicationClosed, isProduction} from 'lib/const'
import {Children, Suspense, useEffect, useState} from 'react'
import {Navigate, Route, Routes, useLocation, useSearchParams} from 'react-router-dom'
import Layout from '../components/Common/Layout'
import LoadingAnimation from '../components/Common/LoadingAnimation'
import Sidebar from '../components/Common/Sidebar'
import DancerDetails from '../components/Dancer/DancerDetails'
import SchoolDetails from '../components/School/SchoolDetails'
import {Role} from '../models/Role'
import Page404 from '../pages/404'
import ApplicationsClosed from '../pages/ApplicationsClosed'
import {HasAuth, RequireAuth} from '../utils/RequireAuth'
import Store from '../utils/Store'
import {
  privateRoutes,
  ROUTE_APPLICATIONS_CLOSED,
  ROUTE_DANCER,
  ROUTE_DETAILS,
  ROUTE_ROOT,
  ROUTE_SCHOOL,
  ROUTE_SIGN_IN,
  routes,
} from './routes'

const useReloadDataOnLocationChanged = (location: ReturnType<typeof useLocation>) => {
  const [lastFetchAllDat, setLastFetchAllData] = useState(new Date())
  useEffect(() => {
    // route change detected
    // if lastFetchAllData is more than 5 minutes ago, fetch all data
    const now = new Date()
    const diff = now.getTime() - lastFetchAllDat.getTime()
    const seconds = Math.floor(diff / 1000)
    const maxSeconds = isProduction ? 5 * 60 : 5
    if (seconds > maxSeconds) {
      Store.loadAllData()
      setLastFetchAllData(now)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname])
}

const AnimatedRouter = () => {
  const loaded = Store.allDataLoaded.use()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const locationArr = location.pathname?.split('/') ?? []
  const userRole = Store.role.use()
  const isAdmin = userRole === Role.SUPER_ADMIN
  const isAdminAccess = searchParams.get('admin') === 'true'
  const [shouldRedirect, setShouldRedirect] = useState(false)
  useReloadDataOnLocationChanged(location)

  // Preserve admin access only if coming from admin access path
  const getRedirectPath = (path: string) => {
    const redirectPath = path || ROUTE_ROOT
    if (isAdminAccess && location.pathname === ROUTE_SIGN_IN) {
      return `${ROUTE_SIGN_IN}?redirectTo=${redirectPath}&admin=true`
    }
    return `${ROUTE_SIGN_IN}?redirectTo=${redirectPath}`
  }

  useEffect(() => {
    if (loaded) {
      // Add a small delay to ensure store is fully loaded
      const timer = setTimeout(() => {
        const shouldAllowAccess =
          isAdmin ||
          location.pathname === ROUTE_APPLICATIONS_CLOSED ||
          (location.pathname === ROUTE_SIGN_IN && isAdminAccess)

        setShouldRedirect(isApplicationClosed && !shouldAllowAccess)
      }, 600)

      return () => clearTimeout(timer)
    }
  }, [loaded, isAdmin, location.pathname, isAdminAccess])

  if (shouldRedirect) {
    return <Navigate to={ROUTE_APPLICATIONS_CLOSED} replace />
  }

  return loaded ? (
    <Suspense fallback={<LoadingAnimation h='100vh' />}>
      <AnimatePresence mode='wait'>
        <Routes location={location} key={locationArr[1]}>
          <Route path={ROUTE_APPLICATIONS_CLOSED} element={<ApplicationsClosed />} />
          <Route path={ROUTE_ROOT} element={<Layout />}>
            {Children.toArray(
              routes.map(({element, ...routeProps}) => (
                <Route element={<HasAuth>{element}</HasAuth>} {...routeProps} />
              )),
            )}
            {Children.toArray(
              privateRoutes.map(({element, ...privateRouteProps}) => (
                <Route
                  element={
                    <RequireAuth redirectTo={getRedirectPath(privateRouteProps.path || '')}>
                      {element}
                    </RequireAuth>
                  }
                  {...privateRouteProps}
                />
              )),
            )}
            <Route
              path={ROUTE_DETAILS}
              element={
                <RequireAuth redirectTo={getRedirectPath(ROUTE_DETAILS)}>
                  <Sidebar />
                </RequireAuth>
              }>
              <Route path={`${ROUTE_DETAILS}${ROUTE_DANCER}`} element={<DancerDetails />} />
              <Route path={`${ROUTE_DETAILS}${ROUTE_SCHOOL}`} element={<SchoolDetails />} />
            </Route>
            <Route path='*' element={<Page404 />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </Suspense>
  ) : (
    <LoadingAnimation h='100vh' />
  )
}

export default AnimatedRouter
