import isEmpty from 'lodash/isEmpty'
import XLSX from 'xlsx-js-style'
import Store from './Store'

const debugLog = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

export const swapArray = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const logout = () => Store.logout()

export const downloadStats = () => {
  downloadStatsFile(true)
  downloadStatsFile(false)
}

// Deprecated
const downloadStatsFile = (matchMode?: boolean) => {
  const dancers = Store.dancers.get()
  const schools = Store.schools.get()
  const schoolsWithDancerIds = schools.map(school => ({
    ...school,
    dancersIds: school.dancers?.map(d => d.id) ?? [],
    selectedDancersIds: school.selectedDancers?.map(d => d.id) || [],
  }))

  let csvCell = ['Dossard', 'Prénom', 'Nom', 'Prix/Finalistes']
    .concat(schoolsWithDancerIds.map(school => school.name!))
    .concat([
      matchMode ? 'Total matches' : 'Total offers',
      matchMode ? 'Total matches finalists' : 'Total offers finalists',
    ])
  const csvRows = [csvCell.join(';')]
  dancers.forEach(dancer => {
    csvCell = [
      dancer.REF ?? '',
      dancer.Firstname ?? '',
      dancer.Lastname ?? '',
      isEmpty(dancer.Eligible_prix)
        ? ''
        : dancer.Eligible_prix?.includes('Finalist')
          ? 'Finalist'
          : dancer.Eligible_prix[0],
    ]
    schoolsWithDancerIds.forEach(school => {
      const bidirectionalMatch =
        school.selectedDancersIds.includes(dancer.id) &&
        (matchMode ? school.dancersIds.includes(dancer.id) : true)
      csvCell.push(bidirectionalMatch ? '1' : '')
    })
    const total = csvCell.filter(cell => cell === '1').length
    csvCell.push(`${total}`)
    csvCell.push(`${dancer.Eligible_prix?.includes('Finalist') ? total : ''}`)
    csvRows.push(csvCell.join(';'))
  })

  const csvData = new Blob([csvRows.join('\n')], {type: 'text/csv;charset=utf-8;'})
  const csvUrl = URL.createObjectURL(csvData)
  const hiddenElement = document.createElement('a')
  hiddenElement.href = csvUrl
  hiddenElement.target = '_blank'
  hiddenElement.download = matchMode
    ? 'Prix de Lausanne match stats export.csv'
    : 'Prix de Lausanne school selections stats export.csv'
  hiddenElement.click()
}

export const downloadStatsExcel = async () => {
  // reload all data to be sure to have the latest data
  await Store.loadAllData()
  const dancers = Store.dancers.get()
  const schools = Store.schools.get()

  debugLog('dancers', dancers)
  debugLog('schools', schools)
  debugLog('=== Starting Excel Generation ===')
  debugLog(`Dancers: ${dancers.length}, Schools: ${schools.length}`)

  // Create workbook and empty worksheet
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet([], {header: []})

  // Set initial worksheet range based on data size
  const initialRange = {
    s: {r: 0, c: 0},
    e: {r: schools.length + 4, c: dancers.length + 1},
  }
  ws['!ref'] = XLSX.utils.encode_range(initialRange)

  debugLog('Created empty worksheet:', ws['!ref'])

  // Style constants
  const styles = {
    match: {
      fill: {
        bgColor: {indexed: '64'},
        fgColor: {rgb: 'FF90EE90'},
      },
      border: {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom: {style: 'thin', color: {rgb: '000000'}},
        left: {style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}},
      },
    }, // Green
    schoolSelect: {
      fill: {
        bgColor: {indexed: '64'},
        fgColor: {rgb: 'FFE6E6FA'},
      },
      border: {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom: {style: 'thin', color: {rgb: '000000'}},
        left: {style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}},
      },
    }, // Light purple (Lavender)
    dancerSelect: {
      fill: {
        bgColor: {indexed: '64'},
        fgColor: {rgb: 'FFE0E0E0'},
      },
      border: {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom: {style: 'thin', color: {rgb: '000000'}},
        left: {style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}},
      },
    }, // Light grey
    header: {
      font: {bold: true, sz: 8},
      border: {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom: {style: 'thin', color: {rgb: '000000'}},
        left: {style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}},
      },
    },
    data: {
      font: {sz: 7},
      border: {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom: {style: 'thin', color: {rgb: '000000'}},
        left: {style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}},
      },
      alignment: {
        wrapText: true,
        vertical: 'center',
      },
    },
  }

  // Helper function to add a row of data
  const addRow = (rowNum: number, data: any[], style?: any) => {
    debugLog(`Adding row ${rowNum}:`, data)
    data.forEach((value, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({r: rowNum - 1, c: colIndex})
      ws[cellRef] = {
        v: value,
        t: typeof value === 'number' ? 'n' : 's',
        s: style,
      }
      debugLog(`Set cell ${cellRef}:`, ws[cellRef])
    })
  }

  // Add legend rows
  debugLog('Adding legend rows...')
  // Legend header
  addRow(1, ['Legend:', '', '', ''])
  ws['A1'].s = styles.header

  // Match row
  addRow(2, ['■ Match', '', '', ''])
  ws['A2'].s = {...styles.header, ...styles.match}

  // School Selection row
  addRow(3, ['■ School Selection', '', '', ''])
  ws['A3'].s = {...styles.header, ...styles.schoolSelect}

  // Dancer Selection row
  addRow(4, ['■ Dancer Selection', '', '', ''])
  ws['A4'].s = {...styles.header, ...styles.dancerSelect}

  // Add empty spacing row
  debugLog('Adding spacing row...')
  addRow(5, Array(dancers.length + 2).fill(''))

  // Add REF row
  debugLog('Adding REF row...')
  const refRow = ['', ...dancers.map(d => d.REF ?? '')]
  addRow(6, refRow)
  // Apply vertical style to each cell in REF row except first column
  for (let i = 1; i < refRow.length; i++) {
    const cellRef = XLSX.utils.encode_cell({r: 5, c: i})
    ws[cellRef].s = {
      ...styles.header,
      alignment: {
        textRotation: 90,
        vertical: 'center',
        horizontal: 'center',
        wrapText: false,
      },
    }
  }
  debugLog('REF row added with length:', refRow.length)

  // Add name row
  debugLog('Adding name row...')
  const nameRow = [
    '',
    ...dancers.map(d => {
      const firstInitial = d.Firstname ? `${d.Firstname[0]}.` : ''
      return `${firstInitial}${d.Lastname ?? ''}`
    }),
  ]
  addRow(7, nameRow)
  // Apply vertical style to each cell in name row except first column
  for (let i = 1; i < nameRow.length; i++) {
    const cellRef = XLSX.utils.encode_cell({r: 6, c: i})
    ws[cellRef].s = {
      ...styles.header,
      alignment: {
        textRotation: 90,
        vertical: 'center',
        horizontal: 'center',
        wrapText: false,
      },
    }
  }
  debugLog('Name row added with length:', nameRow.length)

  // Add school rows with selection data
  debugLog('Adding school rows...')
  schools.forEach((school, index) => {
    const row = index + 8 // Updated row number to account for new legend rows
    debugLog(`Processing school ${school.name} at row ${row}`)
    const rowData = [school.name ?? '', ...Array(dancers.length).fill('')]
    addRow(row, rowData, styles.data)

    // Add selection styles
    dancers.forEach((dancer, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({r: row - 1, c: colIndex + 1})
      const isSchoolSelection = school.selectedDancers?.some(d => d.id === dancer.id)
      const isDancerSelection = school.dancers?.some(d => d.id === dancer.id)

      let style = styles.data
      if (isSchoolSelection && isDancerSelection) {
        style = {...styles.data, ...styles.match}
      } else if (isSchoolSelection) {
        style = {...styles.data, ...styles.schoolSelect}
      } else if (isDancerSelection) {
        style = {...styles.data, ...styles.dancerSelect}
      }

      ws[cellRef].s = style
      debugLog(`Set cell ${cellRef} style:`, style)
    })
  })

  // Add totals rows with more spacing
  const totalRowStart = schools.length + 10 // Reduced from 12 to 10 to remove 2 empty rows

  // Add spacing rows before totals
  for (let i = 0; i < 1; i++) {
    // Reduced from 3 to 1 empty row
    addRow(totalRowStart - 1 + i, Array(dancers.length + 2).fill(''))
  }

  // Calculate totals for each dancer
  const totals = dancers.map(dancer => {
    const isFinalist = dancer.Eligible_prix?.includes('Finalist')
    // A match is when a dancer is both selected by the school and has selected the school
    const matches = schools.filter(
      school =>
        school.dancers?.some(d => d.id === dancer.id) &&
        school.selectedDancers?.some(d => d.id === dancer.id),
    ).length
    // An offer is when a school has selected the dancer
    const offers = schools.filter(school =>
      school.selectedDancers?.some(d => d.id === dancer.id),
    ).length

    return {
      matches,
      offers,
      isFinalist,
    }
  })

  // Add totals rows
  ws[`A${totalRowStart}`] = {v: 'Total matches', t: 's', s: {font: {bold: true, sz: 9}}}
  for (let i = 0; i < dancers.length; i++) {
    const cellRef = XLSX.utils.encode_cell({r: totalRowStart - 1, c: i + 1})
    ws[cellRef] = {
      v: totals[i].matches > 0 ? totals[i].matches.toString() : '',
      t: 's',
      s: {
        font: {bold: true, sz: 9},
        ...(totals[i].isFinalist && {
          fill: {
            bgColor: {indexed: '64'},
            fgColor: {rgb: 'FFFFF0B3'},
          },
        }),
      },
    }
  }

  // Add total offers row
  addRow(totalRowStart + 1, ['Total offers', ...Array(dancers.length).fill('')], {
    font: {bold: true, sz: 9},
  })
  for (let i = 0; i < dancers.length; i++) {
    const cellRef = XLSX.utils.encode_cell({r: totalRowStart, c: i + 1})
    ws[cellRef] = {
      v: totals[i].offers > 0 ? totals[i].offers.toString() : '',
      t: 's',
      s: {
        font: {bold: true, sz: 9},
        ...(totals[i].isFinalist && {
          fill: {
            bgColor: {indexed: '64'},
            fgColor: {rgb: 'FFFFF0B3'},
          },
        }),
      },
    }
  }

  // Update worksheet range to include the extra spacing
  const range = {
    s: {r: 0, c: 0},
    e: {r: totalRowStart + 2, c: dancers.length + 1},
  }
  ws['!ref'] = XLSX.utils.encode_range(range)
  debugLog('Set worksheet range:', ws['!ref'])

  // Set column widths
  ws['!cols'] = [
    {wch: 40}, // School names column (40 characters)
    ...Array(dancers.length).fill({wch: 2.5}), // Slightly narrower for better vertical alignment
  ]
  debugLog('Set column widths')

  // Configure print settings
  ws['!printSetup'] = {
    paperSize: 59, // A0 paper
    orientation: 'landscape',
    fitToPage: true,
    fitToHeight: 1,
    fitToWidth: 1,
  }
  debugLog('Configured print settings')

  // Freeze first row and column
  ws['!freeze'] = {xSplit: 1, ySplit: 4} // Freeze first column and 4 rows
  debugLog('Set freeze panes')

  // Debug worksheet state
  debugLog('=== Final Worksheet State ===')
  debugLog('Keys:', Object.keys(ws))
  debugLog('Range:', ws['!ref'])
  debugLog('Sample cells:', {
    A1: ws['A1'],
    B1: ws['B1'],
    A3: ws['A3'],
    B3: ws['B3'],
  })

  // Add worksheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Selections Matrix')
  debugLog('Added worksheet to workbook')

  // Write file with compatibility note
  XLSX.writeFile(wb, 'Prix de Lausanne Selections Matrix (open with Excel or Google Sheets).xlsx')
  debugLog('File written successfully')

  return wb
}
